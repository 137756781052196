.chat-history {
    max-height: 300px;
    overflow-y: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  
  .message {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .message-controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .voice-input {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }