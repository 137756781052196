html {
  font-size: 16px;
}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header, .navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 1rem; */
  background-color: #4b42ff;
  background-color: rgba(75, 66, 255, 1); /* Fallback color */
  color: white;
}

.navbar {
  position: sticky;
  top: 0;
  z-index: 9999;
}

.brand, .login {
  font-size: 1.5rem; /* Use rem instead of px */
  font-weight: 700;
  transition: color 0.3s ease; /* Transition for hover state */
}

@media (max-width: 600px) {
  .brand, .login {
    font-size: 1.2rem; /* Smaller font size for small devices */
  }
}

@media (min-width: 601px) and (max-width: 900px) {
  .brand, .login {
    font-size: 1.3rem; /* Medium font size for medium devices */
  }
}

@media (min-width: 901px) {
  .brand, .login {
    font-size: 1.5rem; /* Larger font size for large devices */
  }
}

.brand:hover, .login:hover,
.brand:focus, .login:focus {
  color: #ddd; /* Hover and focus state */
}

.robot {
  color:#4b42ff;
}



.content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Align children at the start */
  align-items: stretch; /* Stretch children to fill the container */
  overflow-y: auto; /* Make it scrollable */
  height: calc(80vh - 50px); /* Adjust the height */
}


.sidebar {
  background-color: #f8f9fa;
  border-right: 1px solid #ddd;
  overflow-y: auto;
}
.idea-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center; /* Align items vertically */
  width: 80%;
  margin: 0 auto;
}


.card {
  margin: 10px;
  box-sizing: border-box;
  overflow-y: auto;
  max-height: 40vh;
}

/* For mobile screens */
@media (max-width: 600px) {
  .card {
    width: 100%;
  }
}

/* For tablet screens */
@media (min-width: 601px) and (max-width: 900px) {
  .card {
    width: 100%;
  }
}

/* For desktop screens */
@media (min-width: 901px) {
  .card {
    width: calc(33.33% - 20px);
  }
}

.active.card {
  background-color: #282c34;
  color: white;
}

.status {
  position: fixed; /* Change this line */
  display: flex;
  justify-content: center;
  align-items: stretch;
  bottom: 0;
  width: 100vw;
  z-index: 1000;
  height: 50px;
}

.idea-container-centered {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-out;
  width: 70%;
  margin: auto;
}

.idea-container-top {
  position: sticky;
  top: 12%;  
  transition: all 0.5s ease-out;
  animation: bounce 1s;
  animation-fill-mode: forwards;
  width: 80%;
}

/* For mobile devices */
@media (max-width: 600px) {
  .idea-container-top {
    width: 100%; /* Adjust the width as needed */
  }
}

/* For tablet devices */
@media (min-width: 601px) and (max-width: 900px) {
  .idea-container-top {
    width: 90%; /* Adjust the width as needed */
  }
}

/* For desktop devices */
@media (min-width: 901px) {
  .idea-container-top {
    width: 80%; /* Adjust the width as needed */
  }
}

.grants-container {
  overflow: auto;
  width: 80%;
  padding: 2vw;
  margin: 0 auto; /* Center the container */
  justify-content: center;
  
}


.grants-container h3 {
  margin-bottom: 20px;
  color: #333;
}

/* For mobile devices */
@media (max-width: 600px) {
  .grants-container {
    width: 95%; /* Adjust the width as needed */
  }
}

/* For tablet devices */
@media (min-width: 601px) and (max-width: 900px) {
  .grants-container {
    width: 90%; /* Adjust the width as needed */
  }
}

/* For desktop devices */
@media (min-width: 901px) {
  .grants-container {
    width: 80%; /* Adjust the width as needed */
  }
}

@media (max-width: 600px) {
  .content-container {
    flex-direction: column;
  }
  .idea-container, .grants-container {
    width: 100%; 
    flex-shrink: 0;
  }
}

table {
  width: 100%;
  border-collapse: collapse; /* Collapse borders */
}

th, td {
  border: 1px solid black; /* Add borders to table cells */
  padding: 8px; /* Add some padding */
}

th {
  background-color: #f2f2f2; /* Add a background color to the headers */
}

.app-container {
  display: flex;
  flex-direction: column;
}

.idea-container-top {
  order: 1;
}

.content-container {
  order: 2;
}

.grants-container {
  order: 3;
}

.idea-container {
  order: 4;
}

.status {
  order: 5;
}

input, button {
  padding: 0%;
  border: none;
}