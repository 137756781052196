.idea-choice-card .card-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
}

.confidence-circle {
        width: 4vw;
        height: 4vh;
        border-radius: 50%;
        background-color: #f5f5f5;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1rem; /* Use rem for scalable text */
        font-weight: 700;
        color: #000;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.arrow.down {
        border: solid black;
        border-width: 0 3px 3px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
}

.related-grants {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        background-color: #f5f5f5;
        color:#000;
        padding: 10px; /* Some padding */
        border-radius: 5px; /* Rounded corners */
        font-size: 1rem; /* Use rem for scalable text */
}

.spinner-container {
        display: flex;
        justify-content: flex-end;
}

/* For tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    .confidence-circle {
        font-size: 0.8rem; /* Slightly smaller text */
    }

    .related-grants {
        font-size: 0.8rem; /* Slightly smaller text */
    }
}

/* For mobile phones */
@media (max-width: 767px) {
    .confidence-circle {
        font-size: 0.6rem; /* Even smaller text */
    }

    .related-grants {
        font-size: 0.6rem; /* Even smaller text */
    }
}

.card-body {
    padding:0;
}