/* src/Dashboard.css */
.dashboard-container {
    margin: 2em auto;
    max-width: 1000px;
    padding: 1em;
    background: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.user-info, .ideas-section, .sessions-section {
    margin-bottom: 2em;
}

.user-info h2, .ideas-section h2, .sessions-section h2 {
    margin-bottom: 0.5em;
    font-size: 1.5em;
    border-bottom: 2px solid #0073e6;
    padding-bottom: 0.3em;
    display: inline-block;
}

.card {
    margin-bottom: 1em;
    padding: 1em;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.idea-card, .session-card {
    margin-bottom: 1em;
}

.ideas-container, .sessions-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

button {
    width: auto;
    padding: 0.5em 1em;
    background-color: #0073e6;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
}

button:hover {
    background-color: #005bb5;
}

.delete-account-btn {
    background-color: #e60000;
    margin-left: 1em;
}

.delete-account-btn:hover {
    background-color: #b30000;
}

label {
    display: block;
    margin: 0.5em 0;
}

input[type="text"], input[type="email"] {
    padding: 0.5em;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 0.2em;
}
