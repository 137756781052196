.cursor {
  display: inline-block;
  width: auto;
  background-color: currentColor;
  animation: blink 1s steps(2, start) infinite;
  color: black;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

.landing-text {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 15vh; /* relative to viewport height */
  width: 100vw; /* relative to viewport width */
  font-size: 2vw; /* scale font size */
}

.dynamic-text {
  margin: 0;
  color: #4b42ff;
}

/* For tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .landing-text {
    top: 15vh;
    font-size: 1.5vw;
  }
}

/* For mobile phones */
@media (max-width: 767px) {
  .landing-text {
    top: 15vh;
    font-size: 1vw;
  }
}