@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.pulse {
  animation: pulse 1s infinite;
}


.mic-button-large {
  font-size: 20vh; /* increased from 15vh */
  width: 25vh; /* increased from 20vh */
  height: 25vh; /* increased from 20vh */
  min-width: 50px; /* minimum width */
  min-height: 50px; /* minimum height */
  max-width: 250px; /* maximum width */
  max-height: 250px; /* maximum height */
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
}

/* For tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
  .mic-button-large {
    font-size: 8vh; /* increased from 10vh */
    width: 20vh; /* increased from 15vh */
    height: 20vh; /* increased from 15vh */
    min-width: 80px; /* minimum width */
    min-height: 80px; /* minimum height */
    max-width: 150px; /* maximum width */
    max-height: 150px; /* maximum height */
  }
}

/* For mobile phones */
@media (max-width: 767px) {
  .mic-button-large {
    font-size: 8vh; /* increased from 5vh */
    width: 15vh; /* increased from 10vh */
    height: 15vh; /* increased from 10vh */
    min-width: 50px; /* minimum width */
    min-height: 50px; /* minimum height */
    max-width: 100px; /* maximum width */
    max-height: 100px; /* maximum height */
  }
}


button {
  margin:0;
}