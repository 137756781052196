form {
    
    margin: 20px auto;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 500px;
  }
  
  /* Styling for form elements */
  form div {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  input[type="text"],
  input[type="password"],
  input[type="email"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
}
  
  button:disabled {
    background-color: #ccc;
  }
  
  button:hover {
    background-color: #004494;
  }
  
  /* Link styling */
  a {
    text-decoration: none;
    color: #0066cc;
    display: block;
    margin-top: 10px;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  /* Error message styling */
  div.error {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-bottom: 15px;
  }
  